.portfolio-page-wrapper {
    padding-top: 80px;
}
.portfolio-area {
    position: relative;
    padding: 100px 10px;
    text-align: center;
}

.portfolio-area img.portfolio-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
}

.portfolio-area .container {
    margin: 0px auto;
}

.portfolio-title h2 {
    color: #fff;
}

.portfolio-contents {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.portfolio-title {
    position: relative;
    margin-bottom: 40px;
}

.single-portfolio-list {
    position: relative;
    background-color: #b190f5;
    border: 2px solid #fff;
    border-bottom: none;
    border-left-width: 1px;
    border-right-width: 1px;
    padding: 15px;
    text-align: left;
    max-width: 400px;
}
.single-portfolio-list img {
    margin-bottom: 20px;
    max-height: 190px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}


.single-portfolio-list h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}
.single-portfolio-list p {
    position: relative;
    margin: 0px auto 15px;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 300;
}

.single-portfolio-list a {
    font-size: 14px;
    display: inline-block;
    text-decoration: underline;
    color: #32ffaa;
    font-weight: 400;
}

.category-buttons {
    position: relative;
    margin: 10px auto 30px;
    text-align: center;
}

.category-buttons button {
    position: relative;
    font-size: 16px;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 25px;
    margin: 5px;
    background-color: #925eff;
    text-transform: capitalize;
    border-radius: 5px;
    display: inline-block;
}

.category-buttons button.active,.category-buttons button:hover {
    background-color: #000;
}

.pagination {
    position: relative;
    margin: 40px auto 0px;
}

.pagination button {
    position: relative;
    color: #fff;
    border: 1px solid #fff;
    margin: 5px;
    padding: 5px 12px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    min-width: 40px;
}

.pagination button.active,.pagination button:hover {
    background-color: #000;
}
/* Portfolio Details CSS */
.portfolio-details-page {
    position: relative;
}

.portfolio-detail {
    position: relative;
    max-width: 1200px;
    margin: 0px auto;
}

.portfolio-detail h2 {
    color: #fff;
    margin: 0px auto 40px;
}

.portfolio-images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    
}

.portfolio-images img {
    max-width: 580px;
    border: 1px solid #b5b5b5;
    height: fit-content;
}

.portfolio-detail p {
    color: #fff;
    font-weight: 300;
}


.my-masonry-grid {
    display: flex; 
    width: auto;
    justify-content: center; 
    gap: 15px;
    padding: 15px;
}
  
  .my-masonry-grid_column { 
    background-clip: padding-box;
  }
  
  .image-item { 
  }
  
  .image-item img {
    width: 100%;
    display: block;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .portfolio-detail a {
    color: #fff;
    font-weight: 700;
    /* text-decoration: underline; */
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #fff;
    display: inline-block;
    padding: 12px 25px;
    border-radius: 5px;
    background-color: #000;
   }
@media screen and (max-width: 1023px) {
 
}

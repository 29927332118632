.skill-area {
    position: relative;
    padding: 120px 10px;
}

.skills-wrapper {
    position: relative;
    margin: 0px auto;
}

.skills-wrapper img.skill-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.skills-content {
    position: relative;
    padding: 50px;
}

.skills-content h3 {
    font-size: 48px;
    color: #fff;
    line-height: 58px;
    font-weight: 500;
    margin-bottom: 50px;
}

ul.skills-lists {
    position: relative;
    list-style-type: none;
    max-width: 800px;
    margin: 0px auto;
}

ul.skills-lists li {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.single-skill {
    position: relative;
    background-color: #8568ff;
    padding: 20px 25px;
    border: 2px solid #fff;
    border-bottom: none;
    border-right-width: 1px;
    border-left-width: 1px;
}

.single-skill p {
    position: relative;
    margin: 0px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 26px;
}

@media screen and (max-width: 1023px) {
    .skills-content h3 {
        font-size: 36px;
        line-height: 44px;
    }
}

